var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"art-piece"},[_c('div',{staticClass:"art-piece__background",style:({
      backgroundImage: ("url(" + (_vm.artwork.header_image) + ")"),
    })}),_c('div',{staticClass:"art-piece__content"},[_c('div',{staticClass:"art-piece__details-box"},[_c('div',{staticClass:"art-piece__details-box_tags"},[(_vm.artwork.sku)?_c('Tag',{staticClass:"tag--text tag--public",attrs:{"value":("SKU: " + (_vm.artwork.sku))}}):_vm._e()],1),_c('h2',{staticClass:"art-piece__details-box_title"},[_vm._v(_vm._s(_vm.artwork.title))]),(_vm.artwork.artist)?_c('router-link',{staticClass:"art-piece__details-box_artist",attrs:{"to":("/artists/" + (_vm.artwork.artist.slug))}},[_vm._v(" "+_vm._s(_vm.artwork.artist.first_name)+" "+_vm._s(_vm.artwork.artist.last_name)+" ")]):_vm._e(),_c('div',{staticClass:"art-piece__description collect-editor collect-editor--white",domProps:{"innerHTML":_vm._s(_vm.artwork.description)}}),(_vm.artwork.size.length > 1)?[(_vm.artwork.years)?_c('span',{staticClass:"art-piece__details-box_details"},[_vm._v(" "+_vm._s(_vm.artwork.years)+" ")]):_vm._e(),_vm._l((_vm.artwork.size),function(size,index){return _c('span',{key:("size-" + index),staticClass:"art-piece__details-box_details"},[_vm._v(" "+_vm._s(size)+" ")])})]:_c('span',{staticClass:"art-piece__details-box_details"},[_vm._v(" "+_vm._s(_vm.getArtworkProperties)+" ")]),(_vm.artwork.availability === 0)?_c('span',{staticClass:"art-piece__details-box_price"},[_vm._v(" $"+_vm._s(_vm.artwork.price)+" ")]):(_vm.artwork.availability === 1)?_c('span',{staticClass:"art-piece__details-box_price"},[_vm._v(" Sold ")]):_vm._e(),_c('div',{staticClass:"art-piece__details-box_buttons"},[(_vm.artwork.availability === 0)?_c('BaseButton',{staticClass:"button button--xs button--white",attrs:{"text":"INQUIRE"},on:{"click":_vm.clickInquire}}):_vm._e(),(_vm.artwork.availability === 0)?_c('BaseButton',{staticClass:"button button--xs button--outline-white",attrs:{"text":"VIRTUAL PLACEMENT"},on:{"click":function($event){return _vm.$router.push({
              name: 'ShopItemVirtualPlacement',
              params: { name: _vm.artwork.title },
            })}}}):_vm._e()],1)],2),(_vm.isCarouselVisible)?_c('div',{staticClass:"carousel-wrapper"},[_c('IconButton',{staticClass:"carousel__button carousel__button--back button--circle button--white",on:{"click":function($event){return _vm.goToSlide('prev')}}},[_c('IconArrowTop',{staticClass:"carousel__button-icon carousel__button-icon"})],1),_c('VueSlickCarousel',_vm._b({ref:"carousel"},'VueSlickCarousel',_vm.carouselSettings,false),_vm._l((_vm.artwork.product_images),function(item,key){return _c('div',{key:("image-" + key),staticClass:"slide"},[_c('img',{staticClass:"image",attrs:{"src":item,"alt":_vm.artwork.title}})])}),0),_c('IconButton',{staticClass:"carousel__button carousel__button--next button--circle button--white",on:{"click":function($event){return _vm.goToSlide('next')}}},[_c('IconArrowTop',{staticClass:"carousel__button-icon carousel__button-icon"})],1)],1):_c('img',{staticClass:"artwork-image",attrs:{"src":_vm.artwork.product_images,"alt":_vm.artwork.title}}),(_vm.randomArtByArtist.length > 0)?_c('div',{staticClass:"art-piece__title-box"},[_c('h2',{staticClass:"art-piece__title-box_title"},[_vm._v(" More by "+_vm._s(_vm.artwork.artist.first_name)+" "+_vm._s(_vm.artwork.artist.last_name)+" ")]),_c('div',{staticClass:"art-piece__title-box_right"},[_c('div',{staticClass:"art-piece__title-box_right-content"},[_c('BaseButton',{staticClass:"button--xs button--outline-white",attrs:{"text":"View more by artist"},on:{"click":function($event){return _vm.$router.push({ path: ("/artists/" + (_vm.artwork.artist.slug)) })}}})],1)])]):_vm._e(),(_vm.randomArtByArtist.length > 0)?_c('div',{staticClass:"art-piece__artworks-box"},_vm._l((_vm.randomArtByArtist),function(art,key){return _c('router-link',{key:("art-" + key),attrs:{"to":{ path: ("/artworks/" + (art.slug)) },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('div',{staticClass:"art-piece__artworks-box_card",on:{"click":navigate}},[_c('div',{staticClass:"image-box"},[_c('img',{staticClass:"image",attrs:{"src":art.search_image,"alt":art.title}}),_c('div',{staticClass:"hover-container"},[_vm._v("Click to View")])]),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(art.title))]),_c('span',{staticClass:"author"},[_vm._v(_vm._s(art.artist.first_name)+" "+_vm._s(art.artist.last_name))])])]}}],null,true)})}),1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }